import axios from "axios";
import * as qs from "qs";
import {
  API_BASE_URL,
  CREATE_RESERVATION,
  GET_AVAILABILITY,
  GET_DICTIONARIES_BY_CRITERIA,
  GET_VEHICLES,
  GET_VEHICLE_DETAILS,
  SEND_VERIFICATION_CODE,
  VALIDATE_VERIFICATION_CODE,
} from "./uri";

const CancelToken = axios.CancelToken;

const mainAxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  Origin: "*",
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

export const sourceCancelTokenGetDictionariesByCriteria = CancelToken.source();
export const getDictionariesByCriteria = (data) =>
  mainAxiosInstance.request({
    method: "get",
    url: GET_DICTIONARIES_BY_CRITERIA,
    cancelToken: sourceCancelTokenGetDictionariesByCriteria.token,
    headers: { "Content-Type": "application/json" },
    params: { ...data },
  });

export const sourceCancelTokengetVehicles = CancelToken.source();
export const getVehicles = (data = {}) =>
  mainAxiosInstance.request({
    method: "get",
    url: GET_VEHICLES,
    cancelToken: sourceCancelTokengetVehicles.token,
    headers: { "Content-Type": "application/json" },
    params: data,
  });

export const sourceCancelTokenGetAvaliability = CancelToken.source();
export const getAvailability = (vehicleId, locationId) =>
  mainAxiosInstance.request({
    method: "get",
    url: `${GET_AVAILABILITY}/${vehicleId}/${locationId}`,
    cancelToken: sourceCancelTokenGetAvaliability.token,
    headers: { "Content-Type": "application/json" },
  });

export const sourceCancelTokenGetVehicleDetails = CancelToken.source();
export const getVehicleDetails = (data = {}) =>
  mainAxiosInstance.request({
    method: "get",
    url: GET_VEHICLE_DETAILS,
    cancelToken: sourceCancelTokenGetVehicleDetails.token,
    headers: { "Content-Type": "application/json" },
    params: data,
  });

export const sourceCancelTokenValidateVerificationCode = CancelToken.source();
export const validateVerificationCode = (data = {}) =>
  mainAxiosInstance.request({
    method: "get",
    url: VALIDATE_VERIFICATION_CODE,
    cancelToken: sourceCancelTokenValidateVerificationCode.token,
    headers: { "Content-Type": "application/json" },
    params: data,
  });

export const sourceCancelTokenSendVerificationCode = CancelToken.source();
export const sendVerificationCode = (data) =>
  mainAxiosInstance.request({
    method: "post",
    url: SEND_VERIFICATION_CODE,
    cancelToken: sourceCancelTokenSendVerificationCode.token,
    headers: { "Content-Type": "application/json" },
    data,
  });

export const sourceCancelTokenCreateReservation = CancelToken.source();
export const createReservation = (data) =>
  mainAxiosInstance.request({
    method: "post",
    url: CREATE_RESERVATION,
    cancelToken: sourceCancelTokenCreateReservation.token,
    headers: { "Content-Type": "application/json" },
    data,
  });
