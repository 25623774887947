import { Box, CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { stepView } from "../commons/gtm-events";
import { useStyles } from "./steps.jss";

const SectionVerification = lazy(() => import('../sections/section-verification/sectionVerification'));

export default function StepFive(props) {
  const { metaData, actions, config, location } = props;
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vehicleDetails, availability, stepsData } = useSelector(
    (state) => state.carBooking
  );
  const slug = location?.state?.slug;
  const meta = {
    metaTitle: metaData(slug).metaTitle,
    metaDesc: metaData(slug).metaDesc,
  };
  const campaign = useSelector((state) => state.trackingDetails?.campaing);

  useEffect(() => {
    if (!vehicleDetails) history.push("/");
    else {
      setLoading(false);
      dispatch(actions.setStepNumber(4));
      stepView({
        formStepName: "SMS_checkout",
        formStepNumber: "step-5",
        event: "formSteps_5",
        eventAction: "lead_vn_step5",
        dealerName: vehicleDetails?.location?.name,
        dealerId: vehicleDetails?.location?.bir,
        modelName: vehicleDetails?.modelName,
        campaign
      });
    }
  }, [vehicleDetails, history, dispatch, actions, campaign]);

  return (
    <>
      <Helmet
        title={meta.metaTitle}
        meta={[
          {
            name: "description",
            content: meta.metaDesc,
          },
        ]}
      />
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" marginY={5}>
          <CircularProgress size={100} color="primary" />
        </Box>
      ) : (
        <Grid container className={classes.container}>
          <Suspense fallback={<Box width="100%" display="flex" justifyContent="center" marginY={5}>
            <CircularProgress size={100} color="primary" />
          </Box>}>
            <SectionVerification
              vehicleDetails={vehicleDetails}
              availability={availability}
              stepsData={stepsData}
              translations={config}
              {...props}
            />
          </Suspense>
        </Grid>
      )}
    </>
  );
}
