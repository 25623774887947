export const pageView = () => {
  window.dataLayer.push({
    brand: process.env.REACT_APP_BRAND,
    countryCode: "PL",
    googleAccount: process.env.REACT_APP_GOOGLE_ACCOUNT,
    languageCode: "pl",
    formType: "test_drive_booking",
    formCategory: "lead_vn",
    formStepNumber: "step-0",
    event: "formOpen",
    pageType: "form",
    businessType: "new-car",
    eventCategory: "Leadform Opening",
    eventAction: "lead_vn_step0",
    eventLabel: "ML-BATD-ONLINE",
  });
};

export const stepView = ({
  formStepName,
  formStepNumber,
  event,
  eventAction,
  eventCategory = "Leadform Steps",
  campaign,
  dealerName,
  dealerId,
  modelName,
  modelChooseType = null,
}) => {
  window.dataLayer.push({
    formType: "test_drive_booking",
    formCategory: 'lead_vn',
    formStepName,
    formStepNumber,
    event,
    formName: "ML-BATD-ONLINE",
    pageType: 'form',
    businessType: 'new-car',
    dealerName: dealerName == null ? "" : dealerName,
    dealerId: dealerId == null ? "" : dealerId,
    modelName: modelName == null ? "" : modelName,
    ...(modelChooseType && { modelChooseType: modelChooseType == null ? "" : modelChooseType }),
    campaing: campaign == null ? "" : campaign,
    eventCategory,
    eventAction,
    eventLabel: "ML-BATD-ONLINE",
  });
};