import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { colorScheme } from "./colour-scheme.jss";
import { globalTheme } from "../global.jss";

const breakpoints = createBreakpoints({});
const palette = colorScheme[process.env.REACT_APP_BRAND];
const primaryFont =
  process.env.REACT_APP_BRAND === "dacia" ? "Block" : "NouvelR";

export const overrideScheme = {
  dacia: {
    overrides: {
      MuiButton: {
        root: {
          borderRadius: "0px",
          minWidth: "150px",
          minHeight: "46px",
          lineHeight: "normal",
        },
        label: {
          fontSize: "1em",
          fontWeight: 700,
          textTransform: "uppercase",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          "@media (hover: none)": {
            "&:hover": {
              color: palette.primary.contrastText,
              boxShadow: "none",
            },
          },
          "&:hover": {
            backgroundColor: palette.common.black,
            color: palette.secondary.bodyText,
            boxShadow: "none",
          },
        },
        outlined: {
          border: "2px solid",
        },
        outlinedPrimary: {
          border: "2px solid",
          borderColor: palette.primary.main,
          "@media (hover: none)": {
            "&:hover": {
              border: "2px solid",
              borderColor: palette.secondary.main,
              color: palette.primary.main,
            },
          },
          "&:hover": {
            border: "2px solid",
            borderColor: palette.secondary.main,
            backgroundColor: palette.secondary.main,
            color: palette.primary.contrastText,
          },
        },
        outlinedSecondary: {
          border: "2px solid",
          borderColor: palette.primary.contrastText,
          color: palette.primary.contrastText,
          "@media (hover: none)": {
            "&:hover": {
              border: "2px solid",
              borderColor: palette.primary.contrastText,
              color: palette.primary.contrastText,
            },
          },
          "&:hover": {
            border: "2px solid",
            borderColor: palette.primary.contrastText,
            backgroundColor: palette.primary.contrastText,
            color: palette.primary.main,
          },
        },
      },
      MuiFormControl: {
        root: {
          width: "100%",
        },
      },
      MuiInput: {
        formControl: {
          "label + &": {
            marginTop: 0,
            marginLeft: 8,
          },
        },
        underline: {
          "&&&&:hover:before": {
            borderBottom: "0px",
          },
          "&:before": {
            borderBottom: "0px",
          },
          "&:after": {
            borderBottom: `0px`,
          },
        },
      },
      MuiInputLabel: {
        shrink: {
          display: "none",
        },
        formControl: {
          color: palette.primary.bodyText,
          fontSize: "1em",
          marginLeft: "32px",
          padding: "17px 8px",
          transform: "none",
        },
      },
      MuiInputBase: {
        root: {
          fontWeight: 500,
        },
        input: {
          padding: "6px 0 6px",
        },
      },
      MuiFormLabel: {
        root: {
          "&$focused": {
            color: palette.common.formLabelFocused,
          },
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: "31px",
        },
      },
      MuiTab: {
        root: {
          fontSize: "1.143em",
          [breakpoints.down("xs")]: {
            width: "100%",
            maxWidth: "100%",
          },
          [breakpoints.up("xs")]: {
            fontSize: "1.143em",
          },
        },
        fullWidth: {
          flexGrow: 0,
        },
        textColorInherit: {
          opacity: "1",
        },
      },
      MuiTabs: {
        flexContainer: {
          justifyContent: "center",
        },
      },
      MuiPrivateTabIndicator: {
        colorSecondary: {
          backgroundColor: "transparent",
        },
      },
      MuiListItem: {
        root: {
          paddingTop: "6px",
          paddingBottom: "6px",
        },
      },
      MuiPaginationItem: {
        page: {
          "&$selected": {
            backgroundColor: "none",
          },
        },
      },
      MuiTypography: {
        gutterBottom: {
          marginBottom: "32px",
        },
      },
      MuiCssBaseline: {
        ...globalTheme
      }
    },
  },
  renault: {
    overrides: {
      MuiButton: {
        root: {
          borderRadius: "0px",
          minWidth: "150px",
          minHeight: "46px",
          lineHeight: "normal",
        },
        label: {
          fontSize: "16px",
          lineHeight: "20px",
          fontWeight: 700,
          textTransform: "lowercase",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
        outlined: {
          border: "2px solid",
        },
        containedPrimary: {
          "@media (hover: none)": {
            "&:hover": {
              color: palette.primary.contrastText,
              boxShadow: "none",
            },
          },
          "&:hover": {
            backgroundColor: palette.secondary.main,
            color: palette.primary.bodyText,
            boxShadow: "none",
          },
        },
        outlinedPrimary: {
          border: "2px solid",
          borderColor: palette.primary.main,
          "@media (hover: none)": {
            "&:hover": {
              border: "2px solid",
              borderColor: palette.primary.main,
              color: palette.primary.contrastText,
            },
          },
          "&:hover": {
            border: "2px solid",
            borderColor: palette.primary.main,
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
          },
        },
        outlinedSecondary: {
          border: "2px solid",
          borderColor: palette.primary.contrastText,
          color: palette.primary.contrastText,
          "@media (hover: none)": {
            "&:hover": {
              border: "2px solid",
              borderColor: palette.primary.contrastText,
              color: palette.primary.contrastText,
            },
          },
          "&:hover": {
            border: "2px solid",
            borderColor: palette.primary.contrastText,
            backgroundColor: palette.primary.contrastText,
            color: palette.primary.main,
          },
        },
      },
      MuiFormControl: {
        root: {
          width: "100%",
        },
      },
      MuiInput: {
        formControl: {
          "label + &": {
            marginTop: 0,
            marginLeft: 8,
          },
        },
        underline: {
          "&&&&:hover:before": {
            borderBottom: "0px",
          },
          "&:before": {
            borderBottom: "0px",
          },
          "&:after": {
            borderBottom: `0px`,
          },
        },
      },
      MuiInputLabel: {
        shrink: {
          display: "none",
        },
        formControl: {
          color: palette.primary.bodyText,
          fontSize: "1em",
          marginLeft: "32px",
          padding: "17px 8px",
          transform: "none",
        },
      },
      MuiInputBase: {
        root: {
          fontWeight: 500,
        },
        input: {
          padding: "6px 0 6px",
          fontFamily: primaryFont,
        },
      },
      MuiFormLabel: {
        root: {
          "&$focused": {
            color: palette.common.formLabelFocused,
          },
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: "31px",
        },
      },
      MuiTab: {
        root: {
          fontSize: "1.143em",
          [breakpoints.down("xs")]: {
            width: "100%",
            maxWidth: "100%",
          },
          [breakpoints.up("xs")]: {
            fontSize: "1.143em",
          },
        },
        fullWidth: {
          flexGrow: 0,
        },
        textColorInherit: {
          opacity: "1",
        },
      },
      MuiTabs: {
        flexContainer: {
          justifyContent: "center",
        },
      },
      MuiPrivateTabIndicator: {
        colorSecondary: {
          backgroundColor: "transparent",
        },
      },
      MuiListItem: {
        root: {
          paddingTop: "6px",
          paddingBottom: "6px",
        },
      },
      MuiPaginationItem: {
        page: {
          "&$selected": {
            backgroundColor: "none",
          },
        },
      },
      MuiTypography: {
        gutterBottom: {
          marginBottom: "32px",
          [breakpoints.up("sm")]: {
            marginBottom: "40px",
          },
        },
      },
      MuiChip: {
        root: {
          fontFamily: primaryFont,
          fontSize: 14,
          fontWeight: "bold",
          color: palette.common.chip,
          borderRadius: 4,
          height: 26,
          lineHeight: "22px",
        },
        outlined: {
          border: `1px solid ${palette.common.chip}`,
          backgroundColor: palette.background.light,
        },
      },
      MuiStepConnector: {
        alternativeLabel: {
          top: 16,
          left: "calc(-50% + 16px)",
          right: "calc(50% + 16px)",
        },
      },
      MuiStepLabel: {
        label: {
          fontWeight: "bold",
          opacity: 0.4,
          "&$active": {
            fontWeight: "bold",
            opacity: 1,
          },
          "&$completed": {
            fontWeight: "bold",
            opacity: 0.4,
          },
        },
      },
      MuiAccordion: {
        root: {
          borderTop: `1px solid ${palette.common.accordionBorder}`,
          "&:before": {
            backgroundColor: "none",
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          padding: "0px 30px",
          "&$expanded": {
            color: palette.primary.contrastText,
            background: palette.primary.main,
            "& > div > h4": {
              color: palette.primary.contrastText,
            },
          },
        },
      },
      MuiAccordionDetails: {
        root: { padding: "20px 30px" },
      },
      MuiCssBaseline: {
        ...globalTheme
      }
    },
  },
};
