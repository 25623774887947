import React, { useContext } from "react";
import { Translations } from "../app/app";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import notFoundImage from "./notFound.png";
import Paragraph from "../components/paragraph/paragraph.js";

export default function NotFound() {
  const config = useContext(Translations);
  const { text, buttonCTA } = config.notFoundData;
  const history = useHistory();

  const redirectToMainPage = () => {
    history.replace({
      pathname: "/",
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mb={5}
    >
      <Box width="80%" maxWidth={1000} m={2}>
        <img style={{ width: "100%" }} alt="404" src={notFoundImage} />
      </Box>

      <Box textAlign="center">
        <Paragraph size={16} xsSize={14} marginBottom={2}>
          {text}
        </Paragraph>
        <Button
          onClick={() => redirectToMainPage()}
          variant="contained"
          color="primary"
        >
          {buttonCTA}
        </Button>
      </Box>
    </Box>
  );
}
