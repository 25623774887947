import { Box, CircularProgress } from "@material-ui/core";
import { useEffect, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { pageView } from "../commons/gtm-events";
import SectionFilters from "../sections/section-filters/sectionFilters";
import { organization } from "../data/schema";

const SectionCarList = lazy(() => import('../sections/section-car-list/sectionCarList'));
const SectionFaq = lazy(() => import('../sections/section-faq/sectionFaq'));

export default function StepOneContainer(props) {
  const { match, pageType, metaData, metaDealerData, actions, config } = props;
  const dispatch = useDispatch();
  const { vehicleType, vehicleDetails, step } = useSelector(
    (state) => state.carBooking
  );

  const meta = {
    metaTitle: match.params.dealer ? metaDealerData(match.params.model, match.params.dealer).metaTitle : metaData(match.params.model).metaTitle,
    metaDesc: match.params.dealer ? metaDealerData(match.params.model, match.params.dealer).metaTitle : metaData(match.params.model).metaDesc,
  };

  useEffect(() => {
    if (vehicleDetails) dispatch(actions.removeVehicleDetails());
  }, [vehicleDetails, step, dispatch, actions]);

  useEffect(() => {
    if (match.params.dealer)
      dispatch(actions.saveBirBranch(match.params.dealer));
  }, [actions, dispatch, match.params.dealer]);

  useEffect(() => {
    const getCampaing =
      new URLSearchParams(window.location.search).get("campaign") ||
      new URLSearchParams(window.location.search).get("CAMPAIGN");
    const getOrigin =
      new URLSearchParams(window.location.search).get("origin") ||
      new URLSearchParams(window.location.search).get("ORIGIN");
    dispatch(
      actions.saveTrackingDetails({
        ...(getOrigin && {
          origin: getOrigin,
        }),
        ...(getCampaing && {
          campaign: getCampaing,
        }),
      })
    );
  }, [dispatch, actions]);

  useEffect(() => {
    dispatch(actions.setStepNumber(0));
    const fetchData = async () => {
      pageView({
        pageType,
        dealerName: match.params.dealer,
        vehicleModel: match.params.model,
      });
      await dispatch(
        actions.fetchDictionaries({
          vehicleType: vehicleType || match.params.type || "osobowe",
          vehicleModel: match.params.model,
        })
      );
      await dispatch(
        actions.fetchVehicles({
          vehicleType: vehicleType || match.params.type || "osobowe",
        })
      );
    };
    fetchData().then(() => {
      dispatch(actions.setStepNumber(0));
    });
    // eslint-disable-next-line
  }, [
    dispatch,
    actions,
    match.params.dealer,
    match.params.model,
    match.params.type,
    pageType,
  ]);

  return (
    <>
      <Helmet
        title={meta.metaTitle}
        meta={[
          {
            name: "description",
            content: meta.metaDesc,
          },
        ]}
      >
        {process.env.REACT_APP_BRAND === "renault" && <script type="application/ld+json">
          {JSON.stringify(organization)}
        </script>}
      </Helmet>
      <Suspense fallback={<Box display="flex" width="100%" height="100vh" alignItems="center">
        <Box width="100%" display="flex" justifyContent="center">
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>}>
        <SectionFilters      {...props} actions={actions}
          translations={config.find(item => item.name === 'SectionFilters').translations} />
        <Suspense fallback={<></>}> <SectionCarList     {...props} actions={actions}
          translations={config.find(item => item.name === 'SectionCarList').translations} /></Suspense>
        <Suspense fallback={<></>}> <SectionFaq      {...props} actions={actions}
          translations={config.find(item => item.name === 'SectionFaq').translations} /></Suspense>
      </Suspense>
    </>
  );
}
