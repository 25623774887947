import readRegularWoff from "./fonts/read/Read-Regular_V1100.woff";
import readRegularWoff2 from "./fonts/read/Read-Regular_V1100.woff2";
import readMediumWoff from "./fonts/read/Read-Medium_V1100.woff";
import readMediumWoff2 from "./fonts/read/Read-Medium_V1100.woff2";
import readLightWoff from "./fonts/read/Read-Light_V1100.woff";
import readLightWoff2 from "./fonts/read/Read-Light_V1100.woff2";
import readBoldWoff from "./fonts/read/Read-Bold_V1100.woff";
import readBoldWoff2 from "./fonts/read/Read-Bold_V1100.woff2";
import nouvelRegularWoff2 from "./fonts/nouvelr/NouvelR-Regular.woff2";
import nouvelRegularTtf from "./fonts/nouvelr/NouvelR-Regular.ttf";
import nouvelBoldWoff2 from "./fonts/nouvelr/NouvelR-Bold.woff2";
import nouvelBoldTtf from "./fonts/nouvelr/NouvelR-Bold.ttf";
import daciaBlockRegularOtf from "./fonts/block/DaciaBlock-Regular.otf";
import daciaBlockRegularTtf from "./fonts/block/DaciaBlock-Regular.ttf";
import daciaBlockBoldOtf from "./fonts/block/DaciaBlock-Bold.otf";
import daciaBlockBoldTtf from "./fonts/block/DaciaBlock-Bold.ttf";

export const globalTheme = {
  "@global": {
    body: {
      fontFamily: "Read",
      fontSize: 14,
      margin: 0,
      backgroundColor: "#fff"
    },
    "@font-face": [
      {
        fontFamily: "Read",
        fontWeight: "500",
        fontStyle: "normal",
        fontDisplay: "auto",
        src: `url(${readRegularWoff}) format("woff")`,
        fallbacks: [{ src: `url(${readRegularWoff2}) format("woff2")` }],
      },
      {
        fontFamily: "Read",
        fontWeight: "600",
        fontStyle: "normal",
        fontDisplay: "auto",
        src: `url(${readMediumWoff}) format("woff")`,
        fallbacks: [{ src: `url(${readMediumWoff2}) format("woff2")` }],
      },
      {
        fontFamily: "Read",
        fontWeight: "400",
        fontStyle: "normal",
        fontDisplay: "auto",
        src: `url(${readLightWoff}) format("woff")`,
        fallbacks: [{ src: `url(${readLightWoff2}) format("woff2")` }],
      },
      {
        fontFamily: "Read",
        fontWeight: "700",
        fontStyle: "normal",
        fontDisplay: "auto",
        src: `url(${readBoldWoff}) format("woff")`,
        fallbacks: [{ src: `url(${readBoldWoff2}) format("woff2")` }],
      },
      ...(process.env.REACT_APP_BRAND === 'renault' ? [
        {
          fontFamily: "NouvelR",
          fontWeight: "500",
          fontStyle: "normal",
          fontDisplay: "auto",
          src: `url(${nouvelRegularWoff2}) format("woff")`,
          fallbacks: [{ src: `url(${nouvelRegularTtf}) format("ttf")` }],
        },
        {
          fontFamily: "NouvelR",
          fontWeight: "400",
          fontStyle: "normal",
          fontDisplay: "auto",
          src: `url(${nouvelRegularWoff2}) format("woff")`,
          fallbacks: [{ src: `url(${nouvelRegularTtf}) format("ttf")` }],
        },
        {
          fontFamily: "NouvelR",
          fontWeight: "700",
          fontStyle: "normal",
          fontDisplay: "auto",
          src: `url(${nouvelBoldWoff2}) format("woff")`,
          fallbacks: [{ src: `url(${nouvelBoldTtf}) format("eot")` }],
        }] : [
        {
          fontFamily: "Block",
          fontWeight: "400",
          fontStyle: "normal",
          fontDisplay: "auto",
          src: `url(${daciaBlockRegularOtf}) format("opentype")`,
          fallbacks: [{ src: `url(${daciaBlockRegularTtf}) format("ttf")` }],
        },
        {
          fontFamily: "Block",
          fontWeight: "700",
          fontStyle: "normal",
          fontDisplay: "auto",
          src: `url(${daciaBlockBoldOtf}) format("opentype")`,
          fallbacks: [{ src: `url(${daciaBlockBoldTtf}) format("ttf")` }],
        },
      ]
      )
    ],
  },
  ".TransformComponent-module_content__TZU5O": {
    width: "100% !important",
  },
  ".TransformComponent-module_container__3NwNd": {
    width: "100% !important",
  },
  ...(process.env.REACT_APP_BRAND === 'dacia' && {
    ".react-multiple-carousel__arrow--left": {
      left: "0px !important",
    },
    ".react-multiple-carousel__arrow--right": {
      right: "0px !important",
    },
  })
};
