import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useEffect, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { stepView } from "../commons/gtm-events";
import Headline from "../components/headline/headline";
import { useStyles } from "./steps.jss";

const SectionSummary = lazy(() => import('../sections/section-summary/sectionSummary'));

export default function StepFour(props) {
  const { metaData, actions, config, location } = props;
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vehicleDetails, availability, stepsData } = useSelector(
    (state) => state.carBooking
  );
  const slug = location?.state?.slug;
  const meta = {
    metaTitle: metaData(slug).metaTitle,
    metaDesc: metaData(slug).metaDesc,
  };
  const [loading, setLoading] = useState(true);
  const campaign = useSelector((state) => state.trackingDetails?.campaing);

  useEffect(() => {
    if (!vehicleDetails) history.push("/");
    else {
      setLoading(false);
      dispatch(actions.setStepNumber(3));
      stepView({
        formStepName: "Summary",
        formStepNumber: "step-4",
        event: "formSteps_4",
        eventAction: "lead_vn_step4",
        dealerName: vehicleDetails?.location?.name,
        dealerId: vehicleDetails?.location?.bir,
        modelName: vehicleDetails?.modelName,
        campaign
      });
    }
  }, [vehicleDetails, history, dispatch, actions, campaign]);

  return (
    <>
      <Helmet
        title={meta.metaTitle}
        meta={[
          {
            name: "description",
            content: meta.metaDesc,
          },
        ]}
      />
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" marginY={5}>
          <CircularProgress size={100} color="primary" />
        </Box>
      ) : (
        <Grid container className={classes.container}>
          <div>
            <Headline
              variant="h2"
              headline={config.headline}
              headlinePrimary={config.headlinePrimary}
              spaceBeetwen
              marginTop={3}
            />
          </div>
          <Suspense fallback={<Box width="100%" display="flex" justifyContent="center" marginY={5}>
            <CircularProgress size={100} color="primary" />
          </Box>}>
            <SectionSummary
              vehicleDetails={vehicleDetails}
              availability={availability}
              stepsData={stepsData}
              translations={config}
              slug={slug}
              {...props}
            />
          </Suspense>
        </Grid>
      )}
    </>
  );
}
