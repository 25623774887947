import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import React, { useContext, useEffect, lazy, Suspense } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import actions from "../actions/allActions";
import { ROUTES } from "../commons/constants";
import { translations } from "../data/translations";
import NotFound from "../not-found/notFound";
import store from "../redux-store";
import StepOne from "../steps/stepOne";
import StepTwo from "../steps/stepTwo";
import StepThree from "../steps/stepThree";
import StepFour from "../steps/stepFour";
import StepFive from "../steps/stepFive";
// import ThanksPage from '../steps/thanks-page/thanksPage';
import theme from "../styles/theme";
import CssBaseline from "@material-ui/core/CssBaseline";

const { REACT_APP_BRAND } = process.env;
export const Translations = React.createContext(translations[REACT_APP_BRAND]);

const HorizontalStepper = lazy(() =>
  import("../components/horizontal-stepper/horizontal-stepper")
);
const Banner = lazy(() => import("../components/banner/banner"));
const LegalText = lazy(() => import("../components/legal-text/legalText"));
const ThanksPage = lazy(() => import("../steps/thanks-page/thanksPage"));

const Steps = {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
};

function App() {
  const config = useContext(Translations);
  const generateClassName = createGenerateClassName({
    disableGlobal: true,
    seed: "app",
    productionPrefix: "class-",
  });

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Translations.Provider value={translations[REACT_APP_BRAND]}>
            <Router>
              <ScrollToTop />
              <Suspense fallback={<></>}>
                <Banner />
              </Suspense>
              <Suspense fallback={<></>}>
                {" "}
                <HorizontalStepper
                  config={config.horizontalStepper}
                  actions={actions}
                />
              </Suspense>
              <Switch>
                {ROUTES[REACT_APP_BRAND].map(
                  (
                    { path, componentType, pageType, metaData, metaDealerData },
                    key
                  ) => (
                    <Route
                      exact
                      path={path}
                      key={key}
                      render={(props) =>
                        React.createElement(Steps[componentType], {
                          ...props,
                          pageType,
                          metaData,
                          metaDealerData,
                          actions,
                          config: config[componentType],
                        })
                      }
                    />
                  )
                )}
                <Suspense fallback={<></>}>
                  <Route
                    path="/formularz-wyslany"
                    render={(props) => (
                      <ThanksPage
                        {...props}
                        config={config.ThanksPage}
                        metaData={{ metaTitle: "Dziękujemy za dokonanie rezerwacji!", metaDesc: "" }}
                        pageType="thanks"
                        actions={actions}
                      />
                    )}
                  />
                </Suspense>
                <Route exact="false" path="*" component={NotFound} />
              </Switch>
            </Router>
            <Suspense fallback={<></>}>
              <LegalText />
            </Suspense>
          </Translations.Provider>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
}

export default App;
