import "@makolab/menu-provider/src";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app/app";

const { REACT_APP_BRAND, REACT_APP_MENU_PROVIDER, REACT_APP_MENU_PROVIDER_TOKEN } = process.env;

(async () => {
  const headerEl = document.getElementById("mlb-one-header");

  if (!headerEl) {
    console.warn("Header #mlb-one-header element not found, skipping");
    return;
  }

  try {

    ReactDOM.render(
      <one-header
        brand={REACT_APP_BRAND}
        token={REACT_APP_MENU_PROVIDER_TOKEN}
        api={REACT_APP_MENU_PROVIDER}
      />,
      headerEl
    );
  } catch (e) {
    console.error(e);
  }
})();

ReactDOM.render(<App />, document.getElementById("root"));

(async () => {
  const footerEl = document.getElementById("mlb-one-footer");

  if (!footerEl) {
    console.warn("Footer #mlb-one-footer element not found, skipping");
    return;
  }
  try {
    ReactDOM.render(
      <one-footer
        brand={REACT_APP_BRAND}
        token={REACT_APP_MENU_PROVIDER_TOKEN}
        api={REACT_APP_MENU_PROVIDER}
      />,
      footerEl
    );
  } catch (e) {
    console.error(e);
  }
})();
