import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  headline: {
    textTransform: "uppercase",
    textAlign: ({ xsTextAlign }) => xsTextAlign,
    fontWeight: ({ fontWeight }) => fontWeight,
    "& span": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: ({ textAlign }) => textAlign,
    },
  },
}));
