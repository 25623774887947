import { colorScheme } from "./schemes/colour-scheme.jss";
import { typographyScheme } from "./schemes/typography-scheme.jss";
import { overrideScheme } from "./schemes/override-scheme.jss";
import { createTheme } from "@material-ui/core/styles";
import { publicUrl } from "../commons/uri";

const primaryFont =
  process.env.REACT_APP_BRAND === "dacia" ? "Block" : "NouvelR";
const palette = colorScheme[process.env.REACT_APP_BRAND];
const typography = typographyScheme[process.env.REACT_APP_BRAND];
const overrides = overrideScheme[process.env.REACT_APP_BRAND];

const themeConfig = createTheme({
  themeName: process.env.REACT_APP_BRAND,
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 670,
      md: 1024,
      lg: 1280,
      xl: 1440,
      xsCarousel: 0,
      smCarousel: 480,
    },
  },
  spacing: 8,
  ...typography,
  ...overrides,
  mlbCustomVariables: {
    primaryFont,
    svgIconUrl: publicUrl(
      `/static/svg/${process.env.REACT_APP_BRAND}-one-icons.svg`
    ),
    publicUrl
  },
});

export default themeConfig;
